"use client";

import styles from "./Dialog.module.scss";
import { ForwardButton } from "../Buttons/ForwardButton/ForwardButton";
import { Step } from "./Step/Step";
import { useCallback, useEffect, useState } from "react";
import { StepFour } from "./StepFour";
import { BackButton } from "../Buttons/BackButton/BackButton";
import { Choice } from "../Buttons/Choice/Choice";
import { CloseButton } from "../Buttons/CloseButton/CloseButton";
import { ButtonSubmit } from "./ButtonSubmit/ButtonSubmit";
import { useContact } from "@/provider/ContextForm";
import { getFormContactTexts, sendFormContact } from "@/utils/fetch-api-dialog";
import { useLocale } from "next-intl";
import { FormTexts } from "@/types/types";
import { validateEmail, validatePhoneNumber } from "@/utils/regex";
import DialogScene from "./DialogScene";

export const Dialog = ({ contact = false }) => {
  const { isContactOpen, setIsContactOpen } = useContact();
  const locale = useLocale();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [active, setActive] = useState(1);
  const [isDisable, setIsDisable] = useState(true);
  const [errorFormData, setErrorFormData] = useState({});
  const [filledCounts, setFilledCounts] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [formInputData, setFormInputData] = useState({
    full_name: "",
    mail: "",
    country: "",
    phone: "",
    comment: "",
  });
  const [messages, setMessages] = useState<FormTexts>({ steps: [] });
  useEffect(() => {
    const fetchFormTexts = async () => {
      setIsLoading(true);
      const texts = await getFormContactTexts(locale);
      setMessages(texts);
      setIsLoading(false);
    };
    fetchFormTexts();
  }, [locale]);

  useEffect(() => {
    const handleBodyOverflow = () => {
      document.body.style.overflowY = isContactOpen ? "hidden" : "auto";
    };

    handleBodyOverflow();

    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [isContactOpen]);

  useEffect(() => {
    // Verificar si la cantidad de opciones seleccionadas es suficiente para el paso actual
    const isSelectionValid = selectedOptions.length >= active;

    // Función auxiliar para verificar si hay opciones seleccionadas válidas
    const hasValidOptions = (stepChoices: string[] = []) =>
      stepChoices.some((option) => selectedOptions.includes(option));

    // Verificar si hay opciones seleccionadas válidas según el paso activo
    const steps = messages.steps;
    const stepChoices = steps[active - 1]?.choices || [];

    setIsDisable(!isSelectionValid || !hasValidOptions(stepChoices));
  }, [active, selectedOptions, messages]);

  useEffect(() => {
    const count = Object.entries(formInputData).filter(([key, value]) => {
      if (key === "mail") {
        return validateEmail(value);
      } else {
        return value !== "";
      }
    }).length;

    setFilledCounts(count);
  }, [formInputData, setFilledCounts]);

  const handleChangeInput = (source, value) => {
    setFormInputData((prevForm) => ({
      ...prevForm,
      [source]: value,
    }));
  };

  const handleNext = useCallback(() => {
    setActive((prev: any) => prev + 1);
  }, [setActive]);

  const handleBack = useCallback(() => {
    if (active <= 1) return;
    setActive(active - 1);
  }, [setActive, active]);
  const stepChoices = (step) => {
    const filteredOptions =
      messages.steps[step - 1]?.choices?.filter((option) =>
        selectedOptions.includes(option)
      ) || [];

    return filteredOptions.map((option) => ({
      type: "paragraph",
      children: [{ type: "text", text: option }],
    }));
  };
  const sendForm = async () => {
    let errors = {};
    if (active === 4) {
      if (formInputData.full_name.trim() === "") {
        errors = { ...errors, full_name: "Complete el nombre" };
      }
      if (formInputData.country.trim() === "") {
        errors = { ...errors, country: "Complete el país" };
      }
      if (formInputData.phone.trim() === "") {
        errors = { ...errors, phone: "Complete el teléfono" };
      }
      if (formInputData.comment.trim() === "") {
        errors = { ...errors, comment: "Complete la descripción" };
      }
      if (!validateEmail(formInputData.mail)) {
        errors = { ...errors, mail: "Ingrese un email válido" };
      }
      if (!validatePhoneNumber(formInputData.phone)) {
        errors = { ...errors, phone: "Ingrese un teléfono válido" };
      }
    }
    if (Object.values(errors).length > 0) {
      setErrorFormData(errors);
    } else {
      setIsLoading(true);

      const payload = {
        data: {
          ...formInputData,
          answers: [
            {
              id: 1,
              title: messages.steps[0]?.title,
              description: messages.steps[0]?.description,
              choices: stepChoices(1),
            },
            {
              id: 2,
              title: messages.steps[1]?.title,
              description: messages.steps[1]?.description,
              choices: stepChoices(2),
            },
            {
              id: 3,
              title: messages.steps[2]?.title,
              description: messages.steps[2]?.description,
              choices: stepChoices(3),
            },
          ],
        },
      };
      try {
        const response = await sendFormContact(payload);
        if (!response) {
          setError("Error al enviar el formulario");
        } else {
          setActive(5);
        }
      } catch (error) {
        setError("Error al enviar el formulario");
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div
      className={`${styles.overlayContainer} ${
        isContactOpen ? styles.active : ""
      }`}
      style={contact ? { display: "flex" } : {}}
    >
      <div
        className={styles.overlay}
        style={
          contact
            ? {
                position: "relative",
                zIndex: 1,
                overflow: "auto",
                backgroundColor: "transparent",
                height: "100%",
              }
            : {}
        }
      >
        <div
          className={`
            ${styles.dialogContainer} 
            ${active === 5 ? styles.stepFive : ""} 
            ${active === 4 ? styles.stepFour : ""}
          `}
          style={
            contact
              ? {
                  backgroundColor: "transparent",
                  height: "85vh",
                }
              : {}
          }
        >
          <div className={styles.choicesQuestions}>
            <div className={styles.choicesClose}>
              {active !== 5 && <Choice choice={selectedOptions} />}
              {!contact && <CloseButton setIsContactOpen={setIsContactOpen} />}
            </div>
            <div className={styles.step}>
              {active === 1 && (
                <div className={`${styles.dialogContent} ${styles.active}`}>
                  <Step
                    title={messages.steps[0]?.title}
                    description={messages.steps[0]?.description}
                    choices={messages.steps[0]?.choices}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    multiple
                  />
                </div>
              )}
              {active === 2 && (
                <div className={`${styles.dialogContent} ${styles.active}`}>
                  <Step
                    title={messages.steps[1]?.title}
                    description={messages.steps[1]?.description}
                    choices={messages.steps[1]?.choices}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                  />
                </div>
              )}
              {active === 3 && (
                <div className={`${styles.dialogContent} ${styles.active}`}>
                  <Step
                    title={messages.steps[2]?.title}
                    description={messages.steps[2]?.description}
                    choices={messages.steps[2]?.choices}
                    setSelectedOptions={setSelectedOptions}
                    selectedOptions={selectedOptions}
                  />
                </div>
              )}
              {active === 4 && (
                <div className={`${styles.dialogContent}  ${styles.active}`}>
                  <StepFour
                    messages={messages.steps[3]}
                    setErrorFormData={setErrorFormData}
                    errorFormData={errorFormData}
                    formData={formInputData}
                    handleInputChange={handleChangeInput}
                  />
                </div>
              )}
            </div>
          </div>

          <div
            className={`${styles.navigationBtnContainer} ${active === 4 ? styles.stepFourSubmit : ""} `}
          >
            {active > 1 && active <= 4 && (
              <BackButton handleClick={handleBack} />
            )}
            {active < 4 ? (
              <ForwardButton disabled={isDisable} onClick={handleNext} />
            ) : active === 4 ? (
              <ButtonSubmit
                filledCounts={filledCounts}
                isLoading={isLoading}
                sendForm={sendForm}
              />
            ) : null}
          </div>

          {active === 5 && (
            <div
              className={` ${styles.canvas} ${active === 5 ? styles.active : ""}`}
            >
              <div className={styles.canvasText}>
                <h2>{messages.steps[4]?.title}</h2>
                <p>{messages.steps[4]?.description}</p>
              </div>
              <div
                className={` ${styles.canvas} ${
                  active === 5 ? styles.active : ""
                }`}
              >
                <DialogScene />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
